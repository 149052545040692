/* banner section */

section.Banner_section .banner_text h1 {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 51px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
  margin-bottom: 20px;
}

section.Banner_section .banner_text p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  /* text-transform: lowercase; */
  color: #3C3C3C;
}

section.Banner_section .banner_text button.btn.Explore {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #8E5AFF;
  padding: 13px 61px;
  margin-top: 20px;
  margin-bottom: 26px;

}


section.Banner_section .banner_text .Link_btn a img {
  /* margin-right: 13px; */
  border-radius: 10px;
  margin-right: 14px;
}

section.Banner_section {
  padding-top: 40px;
  /* padding-bottom: 40px; */
  margin-top: 40px;
}


section.Banner_section .Img_benner {
  margin-top: 44px;
}


/* tabs-Section */

.Tab_menu .nav-pills .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 18.5692px;
  line-height: 23px;
  /* text-transform: capitalize; */
  color: #000000;
  opacity: 0.7;
  padding-right: 33px;
  border-bottom: 8px solid #D9D9D9;
  border-radius: 0;

}

.Tab_menu .nav-pills .nav-link.active,
.Tab_menu .nav-pills .show>.nav-link {
  color: #8E5AFF;
  font-weight: 700;
  background-color: transparent;
  opacity: 1;
  border-bottom: 8px solid #8E5AFF;
}

.Tab_menu {
  margin-top: 30px;
  padding-top: 30px;
}

section.tabs_section {
  background: #1D1D1D;
  padding-top: 8%;
  margin-top: 50px;
}


div#pills-tabContent .card h1.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 23px;
  /* text-transform: capitalize; */
  color: #8F59FF;
  margin-bottom: 10px;
}


div#pills-tabContent h2.card-subtitle {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  /* text-transform: capitalize; */
  color: #FFFFFF;
  height: 100%;
  min-height: 60px;
  margin-top: 30px;
}

div#pills-tabContent p.card-text {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* text-transform: capitalize; */
  color: #FFFFFF;
}

div#pills-tabContent .card {
  background: transparent;
  border: none;
}

div#pills-tabContent .card .card-body {
  padding: 10px 6px;
}

section.tabs_section .tabs_img_list ul.img_list li img {
  width: 100%;
  max-width: 186px;
  margin-right: 40px;
}

section.tabs_section .tabs_img_list ul.img_list li:last-child img {
  max-width: 45px;
}


section.tabs_section .tabs_img_list ul.img_list {
  display: flex;
  /* align-items: center; */
  /* margin-right: 0; */
  padding-left: 0;
  padding-top: 30px;
  list-style: none;
  max-height: 80px;
  align-items: center;
}

section.tabs_section .tabs_img_list {
  padding-top: 7%;
  display: flex;
  justify-content: space-between;
}

section.tabs_section .tabs_img_list .tab_bg {
  margin-top: -226px;
  margin-right: -52px;
}

section.Questions .Questions_heading h1 {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 57px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
}

section.Questions .Questions_heading button.btn {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #FFFFFF;
  outline: none;
  background: #8E5AFF;
  padding: 12px 16px;
  margin: 34px 0;
}

.Questions_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
}

section.Questions {
  padding-top: 6%;
}




section.Questions div#accordionExample .accordion-item button.accordion-button {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 35px;
  text-transform: capitalize;
  color: #3C3C3C;
  outline: none;
  background: transparent;
  padding-left: 0;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-item {
  background-color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #D7CFE8 !important;
}

.TalentShares {
  padding-top: 30px;
  margin-top: 30px;
}


section.Questions div#accordionExample .accordion-item .accordion-body {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
  font-family: 'Open Sans', sans-serif;
  padding-left: 0;
}


section.Questions div#accordionExample .accordion-button:not(.collapsed)::after {
  background-image: url(../../assent/img/minus.png);
  transform: rotate(-90deg);
  background-size: 8px;

}

section.Questions div#accordionExample .accordion-button::after {
  background-image: url(../../assent/img/plus.png)
}

.srcond_div {
  margin-top: 50px;
  border-top: 1px solid #D7CFE8 !important;
}

section.Get-Started .Get_text h1 {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
}

section.Get-Started {
  padding-top: 5%;
}

section.Get-Started .Get_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
}


button#button-addon2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #8E5AFF;
  padding: 13px 17px;
}

body button#button-addon2:focus,
section.Get-Started .Get_text input.form-control:focus {
  box-shadow: none !important
}

section.Get-Started .Get_text input.form-control {
  border: 1px solid #D9D9D9;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
}

section.Get-Started .Get_text .input-group.mb-3 {
  width: 100%;
  max-width: 500px;
  padding: 30px 0;
}


section.Get-Started .Get_text h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
  margin-bottom: 18px;
}

section.Get-Started .Link_btn a img {
  border-radius: 8px;
  padding-left: 4px;
}

.Get_text {
  margin-top: 80px;
}


h1.imagin_text {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 50px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
  margin-bottom: 50px;
  font-family: 'GT America Trial';
}

.card.eran_card h5.card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  /* text-transform: capitalize; */
  color: #3C3C3C;
  font-family: 'GT America Trial';
  padding: 12px 0;
}

.card.eran_card p.card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* text-transform: capitalize; */
  color: #3C3C3C !important;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
    min-height: 115px;
}

.card.eran_card {
  border: 1px solid #D1D1D1;
  padding: 11px 25px;
  padding-right: 0;
}


div#pills-contact .banner_text {
  margin-top: 156px;
}

div#pills-contact .Img_benner {
  margin-top: 101px;
}