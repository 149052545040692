@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.container-fluid.custam-container {
  max-width: 1500px;
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

@font-face {
  font-family: 'GT America Trial';
  src: url(./assent//font/GT-America/GT-America-Extended-Black-Trial.otf);
}

/* navbar css  */

.navbar-expand-lg .navbar-nav a.nav-link {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #000000 !important;
  padding-right: 30px;
  padding-left: 30px;
}

.navbar button.btn {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #FFFFFF;
  outline: none;
  background: #8E5AFF;
  padding: 12px 17px;
  margin-left: 20px;
}


ul.navbar-nav {
  margin-left: auto;
}

.navbar a.active.nav-link,
.navbar a.nav-link:hover {
  border-bottom: 4px solid #8E5AFF;
}



/* footer  */
section.footer_section {
  background: #333333;
  padding-top: 5%;
  padding-bottom: 2%;
}

ul.footer_list {
  padding-left: 0;
  display: flex;
  list-style: none;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
}

ul.footer_list li a {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #FFFFFF;
  text-decoration: none;
}

hr.line {
  background: #3D3D3E !important;
  opacity: 1 !important;
}


.footer_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  /* text-transform: lowercase; */
  color: #FFFFFF;
  margin-bottom: 30px;
  word-break: break-all;
}

.footer_text {
  padding-top: 20px;
}

.text_list p,
.text_list a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  margin-bottom: 0;
}

.text_list {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}



ul.footer_list_icon li a svg {
  background: #FFFFFF;
  /* padding: 10px 0; */
  height: 36px;
  width: 38px;
  border-radius: 30px;
  padding: 10px;
}

ul.footer_list_icon li svg path {
  fill: #000000;
}

ul.footer_list_icon {
  display: flex;
  padding-left: 0;
  align-items: center;
  list-style: none;
}

ul.footer_list_icon li {
  margin: 0 10px;
}

ul.footer_list_icon li a svg:hover {
  background-color: #8F59FF;
  /* color: #fff; */
}

ul.footer_list_icon li a svg:hover>path {

  fill: #fff !important;
}


section.Banner_section .banner_text button.btn.Explore:hover {
  box-shadow: 0px 8px 8px 2px #8f5cf9;
}


button.contact-submit {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #8E5AFF;
  outline: none;
  border: none;
  margin: 0 auto;
  padding: 14px 0;
  margin-top: 30px;
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
}
/* Media queries */


@media (max-width: 1440px) {
  body .get_img {
    margin-top: 30px;
  }

  body section.Our-Artists {
    padding-top: 4%;
    padding-bottom: 1%;
  }

  body .video_text {
    margin-bottom: 20px;
  }

  body ul.video_list li {
    margin-bottom: 20px;
  }

  body .navbar-expand-lg .navbar-nav a.nav-link {
    padding-left: 20px;
    padding-right: 20px;
  }

  body .container-fluid.custam-container {
    max-width: 1366px;
  }


  body section.Banner_section .banner_text h1 {
    font-size: 32px;
    line-height: 45px;

  }


  body section.Banner_section {
    padding-top: 20px;
    margin-top: 20px;
  }

  body section.Banner_section .banner_text p {
    font-size: 16px;
    line-height: 20px;
  }

  body section.Banner_section .banner_text button.btn.Explore {
    font-size: 20px;
    line-height: 26px;
    ;
    padding: 13px 38px;
    margin-top: 20px;
    margin-bottom: 26px;
  }


  body .Link_btn a img {
    width: 100%;
    max-width: 160px;
  }

  /* body .Tab_menu {
    margin-top: 0px;
    padding-top: 0px;
  } */

  body section.Questions {
    padding-top: 4%;
  }

  body section.Questions .Questions_heading h1 {
    /* font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900; */
    font-size: 40px;
    line-height: 50px;
  }

  /* .Get_text {
  margin-top: 0px;
} */


  body section.tabs_section .tabs_img_list .tab_bg {
    margin-right: 0px;
  }

  body section.Get-Started .Get_text h1 {
    font-size: 38px;
  }

  body section.Get-Started {
    padding-top: 6%;
  }

  body button#button-addon2 {
    font-size: 14px;
    line-height: 25px;
    padding: 10px 15px;
  }

  body section.Get-Started .Get_text p {
    font-size: 20px;
    line-height: 26px;
  }

  body section.Get-Started .Get_text h6 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  body section.Get-Started .Link_btn a:last-child img {
    width: 100%;
    max-width: 74px;
  }

  body h1.imagin_text {
    font-size: 30px;
    line-height: 35px;
}
body .card.eran_card h5.card-title {
  font-size: 24px;
}
.card.eran_card p.card-text {
  font-size: 16px;
}

body .card.eran_card {
  margin-bottom: 30px;
  height: 100%;
}
}
@media (max-width: 1366px) {

  body section.Our-Artists {
    padding-top: 1%;
    padding-bottom: 1%;
  }

  body section.lildurk .lil_text p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
  }

  body section.lildurk {
    padding-top: 1%;
  }

  body .container-fluid.custam-container {
    max-width: 1300px;
  }


  body section.Banner_section .banner_text h1 {
    font-size: 30px;
    line-height: 45px;

  }


  body section.Banner_section {
    padding-top: 20px;
    margin-top: 20px;
  }

  body section.Banner_section .banner_text p {
    font-size: 16px;
    line-height: 20px;
  }

  body section.Banner_section .banner_text button.btn.Explore {
    font-size: 20px;
    line-height: 26px;
    ;
    padding: 13px 38px;
    margin-top: 20px;
    margin-bottom: 26px;
  }


  body .Link_btn a img {
    width: 100%;
    max-width: 160px;
  }

  body .Tab_menu {
    margin-top: 30px;
    padding-top: 0px;
  }

  body section.Questions {
    padding-top: 4%;
  }

  body section.Questions .Questions_heading h1 {
    /* font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900; */
    font-size: 40px;
    line-height: 50px;
  }

  /* .Get_text {
  margin-top: 0px;
} */


  body section.tabs_section .tabs_img_list .tab_bg {
    margin-right: 0px;
  }

  body section.Get-Started .Get_text h1 {
    font-size: 36px;
  }

  body section.Get-Started {
    padding-top: 6%;
  }

  body button#button-addon2 {
    font-size: 14px;
    line-height: 25px;
    padding: 10px 15px;
  }

  body section.Get-Started .Get_text p {
    font-size: 20px;
    line-height: 26px;
  }

  body section.Get-Started .Get_text h6 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  body section.Get-Started .Link_btn a:last-child img {
    width: 100%;
    max-width: 74px;
  }


}

@media (max-width: 1240px) {


  body .video_section {
    background: #FFFFFF;
    padding: 25px 26px;
    margin-top: 14px;
    padding-bottom: 4px;
  }

  body section.lildurk .lil_text h1 {
    font-size: 26px;
  }

  body .Artist_heding h1 {
    font-size: 50px;
  }

  body .Artist_heding p {
    font-size: 16px;
  }

  body .navbar-expand-lg .navbar-nav a.nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }


  body .navbar button.btn {
    font-size: 16px;
  }


  body .Get_text {
    margin-top: 45px;
  }

  body .footer_text p {
    font-size: 16px;
  }


  body button#button-addon2:focus,
  body section.Get-Started .Get_text .input-group.mb-3 {
    padding: 20px 0;
  }

  body .card.eran_card h5.card-title {
    font-size: 20px;
    line-height: 27px;
}
.card.eran_card p.card-text {
  font-size: 16px;
  line-height: 24px;
}


}

@media (max-width: 1024px) {

  body .lil_img {
    margin-top: 110px;
  }

  body .get_img {
    margin-top: 30px;
  }


  body section.Our-Artists .card p.card-text {
    max-width: 100%;
    padding: 10px 0;
  }

  body section.Our-Artists .card .card-body {
    padding: 26px 19px;
  }

  body .video_section {
    margin-bottom: 20px;
  }

  body .video_text_list h5 {
    font-size: 14px;
  }


  body .video_text_list p {
    font-size: 12px;
  }

  body section.Artist_section {
    background-size: 58% 100%;
  }

  body .navbar-expand-lg .navbar-nav a.nav-link {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
  }

  body section.Banner_section .banner_text h1 {
    font-size: 24px;
  }

  body section.Banner_section .banner_text button.btn.Explore {
    font-size: 16px;
    line-height: 18px;
    padding: 13px 24px;
    margin-top: 18px;
    margin-bottom: 20px;
  }

  .Link_btn a img {
    width: 100%;
    max-width: 120px;
  }

  body .Tab_menu .nav-pills .nav-link {
    padding-right: 9px;
    padding-left: 0;
  }

  body div#pills-tabContent h2.card-subtitle {
    font-size: 14px;

  }

  body section.tabs_section .tabs_img_list ul.img_list li img {
    max-width: 123px;
    margin-right: 40px;
  }

  body section.Questions .Questions_heading h1 {
    font-size: 30px;
    line-height: 46px;
  }

  body section.Get-Started .Get_text h1 {
    font-size: 30px;
  }

  body button#button-addon2:focus,
  body section.Get-Started .Get_text .input-group.mb-3 {
    padding: 10px 0;
  }

  body .Get_text {
    margin-top: 20px;
  }

  body ul.footer_list li a {
    font-size: 14px;
  }

  body section.footer_section {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  body h1.imagin_text {
    font-size: 30px;
    line-height: 35px;
}
body .card.eran_card {
  text-align: center;
  padding: 11px 10px;
  padding-right: 10px;
}
}

@media (max-width: 996px) {

  .Artist_user_heding h6 {
    font-size: 20px;
    line-height: 18px;
  }

  body section.Banner_section .banner_text button.btn.Explore {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  body .Link_btn {
    text-align: center;
  }

  body section.Banner_section .banner_text h1 {
    text-align: center;
  }

  body section.Banner_section .banner_text h1 br {
    display: none
  }

  body section.Banner_section .banner_text p {
    font-size: 14px;
    text-align: center;
  }

  body .Tab_menu ul {
    justify-content: center;
  }

  body section.tabs_section .tabs_img_list .tab_bg {
    margin-right: 0px;
    margin-top: 0;
  }

  body section.tabs_section .tabs_img_list ul.img_list {
    max-height: 100%;

  }

  body section.tabs_section .tabs_img_list ul.img_list li img {
    max-width: 74px;
    margin-right: 40px;
  }

  body section.Questions .Questions_heading h1 br {
    display: none;
  }

  body section.Questions .Questions_heading h1 {
    font-size: 26px;
    line-height: 46px;
  }

  body section.Questions {
    padding-top: 6%;
  }

  body .Get_text {
    text-align: center;

  }

  body button#button-addon2:focus,
  body section.Get-Started .Get_text .input-group.mb-3 {
    margin: auto;
  }

  body .footer_text p {
    font-size: 14px;
  }

  body ul.footer_list li a {
    font-size: 12px;
  }

  body .footer_text {
    padding-top: 0px;
  }

  body section.Artist_section {
    background-size: contain;
    padding-bottom: 70%;
    background-position: center bottom;
  }

  body .Artist_heding {
    text-align: center;
  }

  body .lil_text {
    max-width: 100%;
    text-align: center;

  }

  body .video_section {
    max-width: 100%;
    /* text-align: center; */
  }

  body .lil_img {
    margin-top: 20px;
  }

  body h1.imagin_text {
    font-size: 24px;
    text-align: center;
}
}

@media (max-width: 767px) {

  body .Tab_menu .nav-pills .nav-link {
    font-size: 14px;
  }

  body div#pills-tabContent .card .card-body {
    text-align: center;
  }

  body section.tabs_section .tabs_img_list {
    flex-flow: column;
  }

  body section.tabs_section .tabs_img_list ul.img_list {
    justify-content: space-evenly;
  }

  body section.tabs_section .tabs_img_list ul.img_list li img {
    margin-right: 0;
  }

  body .Questions_heading {
    flex-flow: column;
  }


  body section.Questions .Questions_heading h1 {
    font-size: 18px;
    line-height: 46px;
  }

  body section.Questions div#accordionExample .accordion-item button.accordion-button {
    font-size: 14px;
    line-height: 20px;
  }

  body section.Questions div#accordionExample .accordion-item .accordion-body {

    font-size: 14px;
  }

  body section.Get-Started .Get_text h1 {
    font-size: 26px;
    line-height: 40px;
  }

  body section.Get-Started .Get_text p {
    font-size: 16px;
    line-height: 26px;
  }

  body section.Get-Started .Get_text h6 {
    font-size: 14px;
    line-height: 18px;
  }

  body .footer_log {
    text-align: center;
  }


  body ul.footer_list {
    justify-content: center;
    flex-flow: wrap;
  }

  body ul.footer_list li {
    margin: 0 4px;
  }

  body .footer_text p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  body .text_list {
    text-align: center;
    flex-flow: column;
  }

  body ul.footer_list_icon {
    margin-top: 20px;
  }

  body .text_list p,
  .text_list a {
    margin-bottom: 10px;
  }

  body .Tab_menu ul {
    justify-content: center;
  }

  body section.Questions .Questions_heading button.btn {
    font-size: 14px;
  }

  body ul.footer_list li a {
    font-size: 10px;
  }

  body .Artist_heding h1 {
    font-size: 30px;
  }

  body section.Artist_section {
    background-size: contain;
    padding-bottom: 88%;
    background-position: center bottom;
  }

  body section.lildurk .lil_text h1 {
    font-size: 20px;
  }

  body section.lildurk .lil_text p {
    font-size: 12px;
    margin-bottom: 0;
  }

  body .video_text h6 {
    font-size: 20px;
  }

  body .video_text button {
    font-size: 14px;
  }

  body .our_heding h1 {
    font-size: 30px;
  }

  .our_heding {
    padding: 30px 0;
  }

  body section.Our-Artists .card .card-body {
    text-align: center;
  }

  body .Link_btn a img {
    margin-bottom: 10px;
  }

  body .video_text_list h5 {
    font-size: 12px;
    margin-bottom: 0;
  }

  body .video_text_list p {
    font-size: 10px;
  }

  body h1.imagin_text br {
    display: none;
}
body h1.imagin_text {
  font-size: 20px;
  text-align: center;
}
body .card.eran_card p.card-text {
  font-size: 16px;
}
}

/* contect */

.Contact_text h1 {
  font-family: 'GT America Trial';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 51px;
  text-transform: capitalize;
  color: #3C3C3C;
  margin-bottom: 20px;
}


.Contact_text p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* text-transform: lowercase; */
  color: #3C3C3C;
}


section.Contact_section {
  padding-top:15%;
  padding-bottom: 15%;
  background-size: 100% 100%;
  background-position: center center;
  background-image: url(assent/img/contact.png);
}


section.Contact_section  a.Link_page {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
}


section.Contact_section  a.active.Link_page {
  color: #8E5AFF;
  font-weight: 700;
}

.back_btn {
  margin-top: 30px;
}


section.Office_section {
  background: #0D1D1D;
  padding: 40px 0;
}


.office_text_div h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 17px;
  /* text-transform: capitalize; */
  color: #8E5AFF;
  font-family: 'GT America Trial';
  /* margin-bottom: 0; */
}

.office_text_div p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* text-transform: capitalize; */
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
}

.office_text {
  display: flex;
  align-items: flex-start;
}

.office_text_div {
  margin-left: 12px;
  margin-right: 12px;
}

.office_text img {
  width: 42px;
  height: 42px;
  object-fit: contain;

}

.touch_heading h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #3C3C3C;
  font-family: 'GT America Trial';
}

.touch_heading p{
  font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
text-align: center;
/* text-transform: capitalize; */
color: #3C3C3C;
margin-bottom: 0;
font-family: 'Open Sans', sans-serif;
width: 100%;
max-width: 630px;
margin: 22px auto;
}

section.TouchUs {
  padding-top: 5%;
  padding-bottom: 5%;
}

.touch_heading form input ,.touch_heading form textarea {
  border: 1px solid #D9D9D9 !important;
  padding: 14px 16px !important;
}

.touch_heading form.row {
  width: 100%;
  padding: 10px 0;
  max-width: 996px;
  margin: 10px auto;
}

.touch_heading form input:focus ,.touch_heading form textarea:focus{
  box-shadow: none !important;
} 

.office_text {
  border-right: 3px solid #9E73FA;
  margin-right: 12px;
}

section.Office_section .col-md-3:last-child .office_text {
  /* background: red; */
  border-right: navajowhite;
}

@media (max-width: 1366px) {
.Contact_text p {
  font-size: 18px;
}
.office_text_div h1 {
  font-size: 18px;
}

.office_text_div p {
  font-size: 14px;
}

.touch_heading h1 {
  font-size: 34px;
}


.touch_heading p {
  font-size: 18px;
  margin: 10px auto;
}

section.TouchUs {
  padding-top: 3%;
  padding-bottom: 3%;
}
}

@media (max-width: 1024px) {
  .Contact_text h1 {
    font-size: 30px;
    line-height: 20px;
  }

  .Contact_text p {
    font-size: 16px;
}

section.Contact_section a.Link_page {
  font-size: 16px;
  line-height: 8px;
}

.touch_heading h1 {
  font-size: 30px;
}
}

@media (max-width: 996px) {
section.Contact_section {
 background-size: cover;
}

.office_text {
  flex-flow: column;
  justify-content: center;
  text-align: center;
}

.dummy {
  width: 100%;
  margin-bottom: 12px;
}

.office_text_div p {
  font-size: 12px;
}

.office_text_div {
  margin-left: auto;
  margin-right: auto;
}

.Contact_text {
  text-align: center;
}

}

@media (max-width: 767px) {
  .back_btn {
    text-align: center;
  }
  
  .office_text {
    margin-bottom: 20px;
  }
  .touch_heading h1 {
    font-size: 26px;
}
}