.Artist_user_heding h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 60px;
    /* text-transform: capitalize; */
    color: #3C3C3C;
    font-family: 'GT America Trial';
}

.Artist_user_heding h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    /* text-transform: capitalize; */
    color: #3C3C3C;
    font-family: 'Open Sans', sans-serif !important;
}

.Artist_user_heding p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* text-transform: capitalize; */
    color: #666666;
    font-family: 'Open Sans', sans-serif !important;
}

.gallery_silder .slick-slide img {
    height: 100%;
    min-height: 446px;
    object-fit: cover;
}

/* .gallery_silder img {
    display: block;
    height: 100%;
    min-height: 441px;
    object-fit: cover;
} */
section.user-Artists {
    /* background-image: url(../../../assent//img/lildurk.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 50% 100%; */
    /* padding-top: 1%;
    padding-bottom: 15%; */
}
.Artist_img {
    margin-top: -26px;
    margin-right: -53px;
}

.back_btn a.Link_page {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* text-transform: uppercase; */
    color: #000000;
    text-decoration: none;
}


.back_btn a.active.Link_page {
    color: #8E5AFF;
    font-weight: 700;
}

.back_btn span {
    margin: 0 5px;
}

.Artist_user_heding {
    margin-top: 36%;
}


section.total-Artists {
    background: #1D1D1D;
    padding: 30px 0;
}


.Total_text h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 38px;
    /* text-transform: capitalize; */
    color: #8F59FF;
}

.Total_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    margin-bottom: 0;
}

.Total_text {
    border-left: 1px solid #565656;
    padding: 25px 22px;
    /* border-right: 1px solid #565656; */
    padding-left: 79px;
}

/* .Total_text.last_text {
    border-right: 1px solid #565656;
} */


.Detail_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* text-transform: capitalize; */
    color: #666666;
    font-family: 'Open Sans', sans-serif !important;
}

.Detail_text button.btn_detail {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    background: #8E5AFF;
    padding: 8px 25px;
    margin-top: 2%;
}

.Detail_text {
    width: 100%;
    max-width: 514px;
    padding-top: 4%;
}

section.DETAIL_Page {
    padding-top: 6%;
    padding-bottom: 6%;
}

section.gallery {
    background: #1D1D1D;
    padding-top: 4%;
    padding-bottom: 10%;
}




h1.Heding_Text_gallery {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 57px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    font-family: 'GT America Trial';
}



h1.Heding_Text_gallery span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    padding-left: 20px;
    font-family: 'Open Sans', sans-serif !important;
}

.gallery_silder {
    position: relative;
}

.gallery_silder button.slick-arrow.slick-next,
.gallery_silder button.slick-arrow.slick-prev {
    position: absolute;
}

.gallery_silder button.slick-arrow.slick-prev {
    top: -12%;
    left: 89%;
    width: 40px;
    height: 40px;
    border-radius: 33px;
    border: 1px solid #B0B0B0;
    transform: translate(-50%, -50%);

}

.gallery_silder button.slick-arrow.slick-next {
    top: -12%;
    right: 5.5%;
    width: 40px;
    height: 40px;
    border-radius: 33px;
    border: 1px solid #B0B0B0;
    transform: translate(-50%, -50%);

}

.gall_img {
    padding: 5px 15px;
}

.gallery_silder {
    margin-left: 2.5%;
    margin-top: 2%;

}

.gallery_silder .slick-prev:before {
    font-family: unset !important;
    content: '<' !important
}

.gallery_silder .slick-next:before {
    content: '>' !important;
    font-family: unset !important;
}

.Bnner_john_text h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    font-family: 'GT America Trial';
}


.Bnner_john_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif !important;
}

section.John_Smith {
    /* height: 100vh; */
    padding-top: 14%;
    background-size: cover;
    background-image: url(../../../assent/img/kapil.png);
    margin-top: -3px;
    padding-bottom: 20%;
}


section.John_Smith .Bnner_john_text {
    width: 100%;
    max-width: 59%;
    margin: 12px auto;
}

.gall_img p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif !important;
    padding-right: 40px;
}

.gall_img span {
    font-style: normal;
    font-weight: 400;
    font-size: 20.16px;
    line-height: 30px;
    /* text-transform: capitalize; */
    color: #8F59FF;
}

.Durk_gallery .gall_img {
    padding: 5px 0px;
}


.Durk_gallery .gall_img img {
    width: 100%;
    margin-top: 50px;

}

.Durk_gallery {
    margin-top: 16%;
}


.Durk_gallery .gallery_silder {
    margin-left: 2.5%;
    margin-top: 6%;
}


.Durk_gallery .gallery_silder button.slick-arrow.slick-prev,
.Durk_gallery .gallery_silder button.slick-arrow.slick-next {
    transform: translate(-50%, -50%);
    top: -42%;

}

.financials_text h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 57px;
    /* text-transform: capitalize; */
    color: #3C3C3C;
    font-family: 'GT America Trial';
}

.financials_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* text-transform: capitalize; */
    color: #666666;
    font-family: 'Open Sans', sans-serif !important;
}

.financials_text span {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    margin-left: 11px;
}



.Financials_div h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 38px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    background: #000000;
    padding: 16px 14px;
    text-align: center;
    font-family: 'Open Sans', sans-serif !important;
    margin-bottom: 0;
}





.Financials_div ul.fine_text {
    list-style: none;
    padding-left: 0;
}

.Financials_div li h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'Open Sans', sans-serif !important;
    margin-bottom: 0;
}

.Financials_div li p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* text-transform: capitalize; */
    color: #000000;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif !important;
}

ul.fine_text li {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 20px 67px;
    border: 1px solid #D9D9D9;
}


.Financials_div.margin_div {
    margin-top: 30px;
    padding-top: 30px;
}

  section.Financials {
    padding-bottom: 4%;
    padding-top: 6%;
}

.financials_text {
  margin-right: 80px;
}
section.John_Smith.Doodie{
    background-image: url(../../../assent/img/bgbig5.png);
}

section.John_Smith.Daylinxl{
    background-image: url(../../../assent/img/bgbig4.png);
}
section.John_Smith.Bighavi{
    background-image: url(../../../assent/img/bgbig2.png);
}
section.John_Smith.Alex{
    background-image: url(../../../assent/img/bgbig1.png);
}
section.John_Smith.luh{
    background-image: url(../../../assent/img/bgbig3.png);
}

@media (max-width: 1440px) {
    body .Artist_img {
        margin-right: -30px;
    }

    .Bnner_john_text h1 {
        font-size: 24px;
    }    
    body .Detail_text {
        width: 100%;
        max-width: 600px;
        padding-top: 2%;
    }
    
    body .gallery_silder button.slick-arrow.slick-prev {
        left: 88%;
    }
    
    
    body section.John_Smith .Bnner_john_text {
        max-width: 90%;
    }
    
    }
    @media (max-width: 1240px) {

        body .Artist_img {
            margin-right: 0;
        }
    .Artist_user_heding h1 {
        font-size: 40px;
        line-height: 60px;
    }
    
    .Artist_user_heding p {
        font-size: 14px;
    }
    
    .back_btn a.Link_page {
        font-size: 16px;
    }
    
    body .Detail_text p {
        font-size: 14px;
        line-height: 24px;
    }
    
    body h1.Heding_Text_gallery {
        font-size: 30px;
    }
    body .gallery_silder {
        margin-left: 0;
    }
    body .Bnner_john_text h1 {
        font-size: 22px;
    }
    
    body .gall_img p {
        font-size: 18px;
        padding-right: 26px;
    }
    
    .Durk_gallery .gallery_silder button.slick-arrow.slick-prev, .Durk_gallery .gallery_silder button.slick-arrow.slick-next {
        transform: translate(-50%, -50%);
        top: -29%;
    }
    body .financials_text h1 {
        font-size: 34px;
    }
    body .financials_text p {
        font-size: 18px;
    }
    
    body .Financials_div h1 {
        font-size: 28px;
    }

    ul.fine_text li {
        padding: 20px 30px;
    }
    }
    @media (max-width: 1024px) {
    /* section.user-Artists {
        background-size: 60% 100%;
    } */
    .Artist_user_heding h1 {
        font-size: 32px;
        line-height: 40px;
    }
    
    .Total_text {
        padding-left: 26px;
    }
    .Total_text h1 {
        font-size: 26px;
    }

    body .Detail_text p {
        font-size: 12px;
    }
    body .Bnner_john_text h1 {
        font-size: 18px;
    }

    section.John_Smith {
        background-position: center;
    }
    body .gallery_silder button.slick-arrow.slick-prev {
        left: 85%;
    }

    body .financials_text h1 {
        font-size: 26px;
    } 
    
    .financials_text {
        margin-right: 0px;
    }

    body .Financials_div h1 {
        font-size: 24px;
    }
}





@media (max-width: 996px) {
.Artist_user_heding {
    margin-top: 5%;
    text-align: center;
}

.gallery_silder .slick-slide img {
    min-height: 100%;
}



/* section.user-Artists {
    background-size: 70% 59%;
    background-position: bottom;
    padding-bottom: 64%;
} */

body .Artist_img {
    margin-top: 16px;
}
.Total_text {
    padding-left: 26px;
    text-align: center;
    border-bottom: 1px solid #565656;
    border-left: none;
}

body .Detail_text {
    text-align: center;
    margin: 0 auto;
}
section.DETAIL_Page {
    padding-top: 3%;
    padding-bottom: 0%;
}

section.gallery {
    padding-top: 4%;
    padding-bottom: 4%;
}
body h1.Heding_Text_gallery {
    font-size: 24px;
}
body .gallery_silder button.slick-arrow.slick-prev {
    left: 82%;
}


body .Bnner_john_text h1 {
    font-size: 14px;
}

.Bnner_john_text p {
    font-size: 18px;
}
body .gall_img p {
    font-size: 16px;
    padding-right: 26px;
}
.gall_img span{
    font-size: 16px;
}

}

@media (max-width: 767px) {

    .Detail_img {
        margin-top: 20px;
    }

    /* section.user-Artists {
        background-size: 100% 58%;
        padding-bottom: 128%;
    } */

    .Total_text h1 {
        font-size: 20px;
    }

    .Total_text p {
        font-size: 16px;
        line-height: 15px;
    }    

    .slick-prev, .slick-next{
        display: none !important;
    }

    h1.Heding_Text_gallery {
        text-align: center;
    }
    body .financials_text h1 {
        font-size: 20px;
        text-align: center;
    }
    body .financials_text p {
        font-size: 16px;
        text-align: center;
    }

    .Financials_div li h5 {
        font-size: 14px;
    }

    ul.fine_text li {
        padding: 20px 10px;
    }
    body .Financials_div h1 {
        font-size: 20px;
    }
}