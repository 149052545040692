section.Artist_section .Artist_heding .Link_btn a img {
    border-radius: 10px;
    margin-right: 14px;
}


section.Our-Artists .card a{
    text-decoration: none;
}
.Artist_heding h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 65px;
    line-height: 82px;
    /* text-transform: capitalize; */
    color: #3C3C3C;
    font-family: 'GT America Trial';
}

.Artist_heding p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    /* text-transform: capitalize; */
    color: #666666;
    font-family: 'Open Sans', sans-serif !important;
}

.Artist_heding .Link_btn {
    margin-top: 50px;
}

section.Artist_section {
    background-image: url(../../assent/img/g2.png);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 50% 100%;
    padding-top: 7%;
    padding-bottom: 9%;
}

section.lildurk {
    background: #1D1D1D;
    padding-top: 5%;
}

section.lildurk .lil_text h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 50px;
    /* text-transform: capitalize; */
    color: #FFFFFF;
    font-family: 'GT America Trial';
}

section.lildurk .lil_text p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    /* text-transform: capitalize; */
    font-family: 'Open Sans', sans-serif !important;
    color: #FFFFFF;
}

.video_text h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'Open Sans', sans-serif !important;
}

.video_text button {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    font-family: 'Open Sans', sans-serif !important;
    text-transform: uppercase;
    color: #8F59FF;
}

.video_section {
    background: #FFFFFF;
    padding: 25px 60px;
    margin-top: 40px;
    width: 100%;
    max-width: 600px;
    padding-bottom: 4px;
    padding-right: 25px;
}

.video_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

ul.video_list {
    padding-left: 0;
    list-style: none;
}

.video_text_list h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'Open Sans', sans-serif;
}

ul.video_list li {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.video_text_list {
    margin-left: 20px;
}

.video_text_list p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'Open Sans', sans-serif !important;
    margin-bottom: 0;
}

.lil_text {
    width: 100%;
    max-width: 548px;
    margin-top: 28px;
}


.our_heding h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 57px;
    /* text-transform: capitalize; */
    color: #3C3C3C;
    text-align: center;
    font-family: 'GT America Trial';
}


section.Our-Artists {
    padding-top: 4%;
    padding-bottom: 4%;
}


section.Our-Artists .card h5.card-title {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 38px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'GT America Trial';
}

section.Our-Artists .card p.card-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* text-transform: capitalize; */
    color: #000000;
    font-family: 'Open Sans', sans-serif !important;
    width: 100%;
    max-width: 332px;
    padding: 15px 0;
    height: 100%;
    min-height: 200px;
}

section.Our-Artists .card a.btn.btn-primary {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    background-color: #1D1D1D;
    outline: none;
}

section.Our-Artists .card a.btn.btn-primary:hover,
.btn-check:focus+.btn-primary,
.btn-primary:focus {
    background: #8E5AFF;
    box-shadow: none !important;
}

section.Our-Artists .card {
    border: none !important;
    background: #FFFFFF;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.18);
    margin-bottom: 30px;
    /* height: 100%; */
}
section.Our-Artists .card .card-body {
    padding: 30px 40px;
}


.Artist_get .Get_text {
    margin-top: 35px;
}


.our_heding {
    padding: 40px 0;
}


.footer_text a  ,.footer_text a:hover  ,.text_list a:hover{
    color: #8f5cf9 !important;
}